import React, { useEffect, useState } from 'react';
import { motion, useAnimate } from 'framer-motion'
import '../styles/MainProfile.css';
import PopoverContent from '../components/PopoverContent';
import { POP_OVER_STATE } from '../utils';

const MainProfile = () => {
	const [ popoverState, setPopoverState ] = useState(POP_OVER_STATE.NONE);
	const [ scope, animate ] = useAnimate();

	useEffect(() => {
		const textElements = document.getElementsByClassName('profile-text');
		const sequence = [
			['#profile', { y: [150, 0], opacity: 1 }, { delay: 1, duration: 0.75}],
			['#profile-pic-card > img', { borderRadius: '50%', borderWidth: '2px' }, { delay: 0.25, duration: 0.5 }],
			[textElements[0], { opacity: 1, x: [150, 0], marginBottom: '1rem' }, { delay: 0.25, duration: 0.5 }],
			[textElements[1], { opacity: 1, y: [150, 0], marginBottom: '1rem' }, { delay: 0.75, duration: 0.5 }],
			[textElements[2], { opacity: 1, y: [150, 0] }, { delay: 0.75, duration: 0.5 }],
		]
		animate(sequence)
	}, []);

	const onLinkClick = (newState) => {
		setPopoverState(newState)
	}

	return (
		<motion.div
			ref={scope}
			id='profile-container'
			animate={{ background: '#e3dece' }}
			transition={{ duration: 1 }}
			onClick={() => setPopoverState(POP_OVER_STATE.NONE)}
		>
			{popoverState !== POP_OVER_STATE.NONE ?
				<PopoverContent popoverState={popoverState} setPopoverState={setPopoverState} />
				:
				null
			}
			<div id='profile'>
				<div id='profile-pic-card'>
					<img alt='' src='/img/profilePic.jpeg'/>
					<div className='profile-text'>
						Hi there, I'm Ye! <span className='emoji-bounce'>🐱</span>
					</div>
				</div>
				<div className='profile-text'>
					I'm a full time software engineer (currently
					<motion.a
						whileHover={{ color: '#fff' }}
						href='https://squarespace.com' target='_blank'> @Squarespace </motion.a>), part time adjunct lecturer and,
					all things web enthusiast. Addicted to coding, ever since my first "Hello, World!" almost
					<motion.a
						tabIndex='1'
						whileHover={{ color: '#fff' }}
						onClick={(e) => {
							e.stopPropagation();
							onLinkClick(POP_OVER_STATE.RESUME)
						}}
					> 10+ years 🗓 </motion.a> ago!
					If I'm not writing code in my free time, you can find me playing handball, video games,
					or just working out and staying active.
				</div>
				<div className='profile-text'>
					Always learning each day, and looking to taking on new challenges! I occasionally like taking on side
					<motion.a
						tabIndex='1'
						whileHover={{ color: '#fff' }}
						onClick={(e) => {
							e.stopPropagation();
							onLinkClick(POP_OVER_STATE.PROJECTS)
						}}
					> projects 📚</motion.a> in my free time, so feel free to
					<motion.a
						tabIndex='1'
						whileHover={{ color: '#fff' }}
						onClick={(e) => {
							e.stopPropagation();
							onLinkClick(POP_OVER_STATE.CONTACT)
						}}
					> reach out 📱</motion.a> to me if you'd like to work with me,
					I'd love to have a chat with you!
				</div>
			</div>
		</motion.div>
	);
};

export default MainProfile;