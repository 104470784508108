import React from 'react';
import '../styles/Contact.css';
import { SocialIcon } from 'react-social-icons';
import { motion, useAnimate } from 'framer-motion';

const Contact = () => {
  const [ scope, animate ] = useAnimate();

  return (
    <div id='contact-container' ref={scope}>
      <div id='social-links'>
        <motion.div
          whileHover={{
            scale: 1.25
          }}
          onHoverStart={() => {
            animate('#contact-info-prompt > div:nth-child(1)', { opacity: 1 })
          }}
          onHoverEnd={() => {
            animate('#contact-info-prompt > div:nth-child(1)', { opacity: 0 })
          }}
        >
          <SocialIcon
            url='mailto:ye@y3p.io'
          />
        </motion.div>
        <motion.div
          whileHover={{
            scale: 1.25
          }}
          onHoverStart={() => {
            animate('#contact-info-prompt > div:nth-child(2)', { opacity: 1 })
          }}
          onHoverEnd={() => {
            animate('#contact-info-prompt > div:nth-child(2)', { opacity: 0 })
          }}
        >
          <SocialIcon
            target="_blank"
            url='https://linkedin.com/in/hello-ye'
          />
        </motion.div>
        <motion.div
          whileHover={{
            scale: 1.25
          }}
          onHoverStart={() => {
            animate('#contact-info-prompt > div:nth-child(3)', { opacity: 1 })
          }}
          onHoverEnd={() => {
            animate('#contact-info-prompt > div:nth-child(3)', { opacity: 0 })
          }}
        >
          <SocialIcon
            target="_blank"
            url='https://github.com/y3pio'
          />
        </motion.div>
        <motion.div
          whileHover={{
            scale: 1.25
          }}
          onHoverStart={() => {
            animate('#contact-info-prompt > div:nth-child(4)', { opacity: 1 })
          }}
          onHoverEnd={() => {
            animate('#contact-info-prompt > div:nth-child(4)', { opacity: 0 })
          }}
        >
          <SocialIcon
            target="_blank"
            url='https://join.slack.com/t/slack-gao6721/shared_invite/zt-2a1rh0bkl-j~_v_JYK208HBrdBtYVUxQ'
          />
        </motion.div>
      </div>
      <div id='contact-info-prompt'>
        <div>Send me an email at: <i>ye@y3p.io</i> 📫</div>
        <div>Connect with me on LinkedIn 🤝</div>
        <div>Code with me on GitHub 💻</div>
        <div>My personal Slack channel for all business/coding related inquiries 💬</div>
      </div>
    </div>
  )
};

export default Contact;