import React, { useState, useEffect } from 'react';
import '../styles/Projects.css';
import { FaCircleChevronRight } from "react-icons/fa6";
import { FaCircleChevronLeft } from "react-icons/fa6";
import { motion, useAnimate } from 'framer-motion';

const PROJECT_ITEMS = [
  <div className='project-item'>
    <img alt='' src='/img/project1.png'/>
  </div>,
  <div className='project-item'>
    <img alt='' src='/img/project2.png'/>
  </div>,
  <div className='project-item'>
    <img alt='' src='/img/project3.png'/>
  </div>,
  <div className='project-item'>
    <img alt='' src='/img/project4.png'/>
  </div>,
  <div className='project-item'>
    <img alt='' src='/img/project5.png'/>
  </div>
]

const Projects = () => {
  const [ index, setIndex ] = useState(0);
  const [ scope, animate ] = useAnimate();

  useEffect(() => {
    animate('#current-project-focus', { opacity: 1 })
  }, [index])

  const onChevronClick = (direction) => {
    animate('#current-project-focus', { opacity: 0 })
      .then(() => {
        if (direction === 'left') {
          if (index === 0) {
            setIndex(PROJECT_ITEMS.length - 1);
          } else {
            setIndex(index - 1);
          }
        } else {
          if (index === PROJECT_ITEMS.length - 1) {
            setIndex(0);
          } else {
            setIndex(index + 1);
          }
        }
      })
  };

  return (
    <div id='project-container' ref={scope}>
      <h1>Projects 📚 & Portfolio 📷</h1>
      <h3>Websites that I work(ed) on, and some shots from my photography days. Ask me all about it!</h3>
      <div id='project-carousel'>
        <motion.div
          whileHover={{
            color: '#7dd87d',
          }}
          whileTap={{
            scale: 0.8
          }}
        >
          <FaCircleChevronLeft className='nav-chevron' onClick={() => onChevronClick('left')}/>
        </motion.div>
        <div id='current-project-focus'>
          {PROJECT_ITEMS[index]}
        </div>
        <motion.div
          whileHover={{
            color: '#7dd87d',
          }}
          whileTap={{
            scale: 0.8
          }}
        >
          <FaCircleChevronRight className='nav-chevron' onClick={() => onChevronClick('right')}/>
        </motion.div>
      </div>
    </div>
  );
};

export default Projects;