import React from 'react';
import '../styles/Intro.css';
import Typewriter from 'typewriter-effect';
import { motion, useAnimate } from 'framer-motion'
import { APP_STATES } from "../utils";

const HandWave = () => {
  return (
    <span className='emoji-wave'>👋</span>
  );
}

const Intro = ({ setAppState }) => {
  const [scope, animate] = useAnimate();
  const onIntroClick = () => {
    animate('*', { opacity: 0 })
      .then(() => {
        setAppState(APP_STATES.MAIN_PROFILE);
      });;
  }

  return (
    <motion.div
      ref={scope}
      id='intro-container'
      onClick={onIntroClick}
      whileHover={{
        scale: 1.2
      }}
      whileTap={{
        scale: 0.8
      }}
    >
      <Typewriter
        onInit={(typewriter) => {
          typewriter.typeString('Hello, World!')
            .start();
        }}
        options={{
          delay: 65,
          loop: false,
          autoStart: true,
          cursor: ''
        }}
      />
      <HandWave />
    </motion.div>
  );
};

export default Intro;