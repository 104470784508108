import { useState } from 'react';
import './App.css';
import Intro from './components/Intro';
import MainProfile from "./components/MainProfile";
import { APP_STATES } from "./utils";

const App  = () =>  {
  const [appState, setAppState] = useState(APP_STATES.INTRO)

  const ProfileContent = () => {
    switch (appState) {
      case APP_STATES.MAIN_PROFILE:
        return (
          <MainProfile />
        )
      case APP_STATES.INTRO:
      default:
        return <Intro setAppState={setAppState}/>
    }
  }

  return (
    <div className="App">
      <ProfileContent />
    </div>
  );
}

export default App;
